import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import { graphql, useStaticQuery, Link } from 'gatsby'
import SanityLink from '~components/SanityLink'
import { mobile, InsetSection } from '~styles/global'
import RichText from '~components/RichText'

const Footer = ({ className, fixed, color, faded, minimal, blackBg }) => {

	const { phoneNumber, emailAddress, instagramUrl, footerTextDesktop, footerTextMobile } = useSiteSettings()

	const data = useStaticQuery(graphql`
		{
			sanityAbout {
				slug {
					current
				}
			}
			sanityArchivePage {
				slug {
					current
				}
			}
		}
	`)

	return(
		<Wrap className={className} fixed={fixed} minimal={minimal} blackBg={blackBg}>
			{(!fixed && !minimal) &&
				<Top>
					<Col className='logo-size'>
						<Mobile><RichText content={footerTextMobile}/></Mobile>
						<Desktop><RichText content={footerTextDesktop}/></Desktop>
					</Col>
				</Top>
			}
			<Bottom color={color} show={!fixed} faded={faded}>
				<BottomSection minimal={minimal}>
					<Border color={color} faded={faded}/>
					<Links 
						className='sans-xs' 
						minimal={minimal}
					>
						<PageLinks minimal={minimal}>
							{/* <Archivelink to={`/${data?.sanityArchivePage?.slug?.current}`}>Archive</Archivelink> */}
							© Mr+Positive {new Date().getFullYear()}
						</PageLinks>
						<Details minimal={minimal}>
							<Tel href={`tel:${phoneNumber}`}>
								{phoneNumber}
							</Tel>
							<Email 	
								minimal={minimal}					
								href=""
								link={{
									url: `mailto:${emailAddress}`
								}}>
								{emailAddress}
							</Email>
							<Insta link={{
								linkType: 'external',
								url: instagramUrl
							}}>Instagram</Insta>
						</Details>
					</Links>
				</BottomSection>
			</Bottom>
		</Wrap>
	)
}

const Wrap = styled.footer`
	position: ${props => props.fixed ? 'fixed' : 'relative'};
	height: ${props => (props.fixed || props.minimal) ? 'auto' : '100vh'};
	background-color: ${props => props.fixed || props.blackBg ? 'transparent' : 'var(--white)'};
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 5;
	display: ${props => props.fixed ? 'block' : 'grid'};
	${props => !props.fixed && 'grid-template-rows: 1fr max-content;'}
	${mobile}{
		min-height: ${props => props.fixed ? 'auto' : '-webkit-fill-available'};
		min-height: ${props => props.fixed ? 'auto' : 'fill-available'};
		max-height: ${props => props.fixed ? 'auto' : '-webkit-fill-available'};
		max-height: ${props => props.fixed ? 'auto' : 'fill-available'};
	}
`
const Top = styled(InsetSection)`
	>div{
		margin: 27px 0;
	}
	${mobile}{
		>div {
			margin: 24px 0 30px;
		}
	}
`
const Col = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 10;
		margin-bottom: 30px;
		p {
			margin-bottom: 0.9em;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
`
const Mobile = styled.div`
	display: none;
	${mobile}{
		display: block;
	}
`
const Desktop = styled.div`
	${mobile}{
		display: none;
	}
`
const Bottom = styled.div`
	height: 52px;
	color: ${props => props.faded ? '#666666' : `var(--${props.color})`};
	a{
		transition: color 0.25s;
		:hover{
			color: ${props => props.faded ? 'var(--pink)' : `var(--${props.color})`};
		}
	}
	${mobile}{
		height: auto;
		display: ${props => props.show ? 'block' : 'none'}
	}
`
const BottomSection = styled(Section)`
	${mobile}{
		>div{
			padding-left: ${props => props.minimal ? '20px' : '83px'};
		}
	}
`
const Border = styled.div`
	grid-column: span 12;
	height: 1px;
	background-color: ${props => props.faded ? '#666666' : `var(--${props.color})`};
	margin-bottom: 14px;
	${mobile}{
		margin-bottom: 12px;
	}
`
const Links = styled.div`
	grid-column: span 12;
	display: flex;
	justify-content: space-between;
	${mobile}{
		margin-bottom: 30px;
		flex-wrap: wrap;
		font-size: ${props => props.minimal ? '12px' : '24px'};
		width: 100%;
	}
`
const PageLinks = styled.div`
	${mobile}{
		margin-bottom: 19px;
		display: ${props => props.minimal ? 'none' : 'block'};
	}
`
const AboutLink = styled(Link)`
	margin-right: 32px;
	${mobile}{
		display: block;
		width: 100%;
		margin-bottom: 19px;
		margin-right: 0;
	}
`
const Archivelink = styled(AboutLink)`
	margin-right: 0;
`
const Details = styled.div`
	display: flex;
	${mobile}{
		flex-wrap: ${props => props.minimal ? 'nowrap' : 'wrap'};
		justify-content: ${props => props.minimal ? 'space-between' : 'flex-start'};
		width: 100%;
	}
`
const Tel = styled.a`
	margin-left: 31px;
	${mobile}{
		margin-left: 0;
		margin-bottom: 19px;
	}
`
const Email = styled(SanityLink)`
	margin-left: 31px;
	${mobile}{
		margin-left: 0;
		margin-bottom: 19px;
		display: block;
		width: ${props => props.minimal ? 'auto' : 'minimal'};
	}
`
const Insta = styled(SanityLink)`
	margin-left: 31px;
	${mobile}{
		margin-left: 0;
		margin-bottom: 19px;
		display: block;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
	fixed: PropTypes.bool,
	color: PropTypes.string,
	faded: PropTypes.bool
}

export default Footer
