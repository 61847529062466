import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import HeaderOverlay from '~components/HeaderOverlay'
import HeaderOverlay2 from '~components/HeaderOverlay2'
import HeaderOverlayPinks from '~components/HeaderOverlayPinks'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import useSafari from '~utils/useSafari'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
	const [fixedTitle, setFixedTitle] = useState(undefined)
	const [fixedFooter, setFixedFooter] = useState(undefined)
	const [footerColor, setFooterColor] = useState(undefined)
	const [fadeTitle, setFadeTitle] = useState(undefined)
	const [footerFade, setFooterFade] = useState(false)
	const [minimalFooter, setMinimalFooter] = useState(false)
	const [blackHeader, setBlackHeader] = useState(false)
	const [blackFooterBg, setBlackFooterBg] = useState(false)
	const safari = useSafari()

	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the cart whenever the route changes
	useEffect(() => {
		closeCart()
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
		}))
		// eslint-disable-next-line
  }, [location.pathname])

	useEffect(() => {
		//  Fixed Header
		if (	
			location?.pathname === '/' ||
			location?.pathname === '/production' || location?.pathname === '/production/'
			|| location?.pathname === '/post' || location?.pathname === '/post/'
			|| location?.pathname === '/directors' || location?.pathname === '/directors/'
		) {
			setFixedTitle(true)
		} else {
			setFixedTitle(false)
		}
		// FadeTitle 
		if (	
			location?.pathname === '/' ||
			location?.pathname === '/production' || location?.pathname === '/production/'
			|| location?.pathname === '/post' || location?.pathname === '/post/'
			|| location?.pathname === '/directors' || location?.pathname === '/directors/'
			|| location?.pathname.includes('production')
		) {
			setFadeTitle(false)
		} else {
			setFadeTitle(true)
		}
		// Fixed Footer
		if (
			location?.pathname === '/' 
			|| location?.pathname === '/production' || location?.pathname === '/production/'
			|| location?.pathname === '/post' || location?.pathname === '/post/'
			|| location?.pathname === '/directors' || location?.pathname === '/directors/'
		){
			setFixedFooter(true)
		} else {
			setFixedFooter(false)
		}
		// Footer Color
		if (
			location?.pathname === '/' 
			|| location?.pathname === '/production' || location?.pathname === '/production/'
			|| location?.pathname === '/post' || location?.pathname === '/post/'
			|| location?.pathname === '/directors' || location?.pathname === '/directors/'
			|| location?.pathname === '/projects' || location?.pathname === '/projects/'
		){
			setFooterColor('white')
		} else {
			setFooterColor('black')
		}
		// Fade footer
		if (
			location?.pathname === '/production' || location?.pathname === '/production/'
			|| location?.pathname === '/post' || location?.pathname === '/post/'
			|| location?.pathname === '/directors' || location?.pathname === '/directors/'
		){
			setFooterFade(true)
		} else {
			setFooterFade(false)
		}

		// minimal footer
		if (
			location?.pathname === '/about-us' || location?.pathname === '/about-us/'
			|| location?.pathname === '/news' || location?.pathname === '/news/'
			|| location.pathname.includes('news') 
			|| location?.pathname === '/projects' || location?.pathname === '/projects/'
		){
			setMinimalFooter(true)
		} else {
			setMinimalFooter(false)
		}
		// black header
		if (
			location?.pathname === '/about-us' || location?.pathname === '/about-us/'
			|| location?.pathname === '/news' || location?.pathname === '/news/'
		){
			setBlackHeader(true)
		} else {
			setBlackHeader(false)
		}
		// black footer background 
		if (location?.pathname === '/projects' || location?.pathname === '/projects/'){
			setBlackFooterBg(true)
		} else {
			setBlackFooterBg(false)
		}
	}, [location.pathname, siteState.menuOpen])

	return (
		<>
			<GlobalStyles />
			{!safari &&
				<Header 
					fixedTitle={fixedTitle}
					fadeTitle={fadeTitle}
					safari={safari}
					black={blackHeader}
					fixedFooter={fixedFooter}
				/>
			}
			<HeaderOverlayPinks
				fixedTitle={fixedTitle}
				fadeTitle={fadeTitle}
				blackPlus={false}
				fixedFooter={fixedFooter}
			/>
			<HeaderOverlay 
				fixedTitle={fixedTitle}
				fadeTitle={fadeTitle}
				black={blackHeader}
				fixedFooter={fixedFooter}
			/>
			<HeaderOverlay2
				fixedTitle={fixedTitle}
				fadeTitle={fadeTitle}
				black={blackHeader}
				fixedFooter={fixedFooter}
			/>

			{siteState.menuOpen &&
				<Global styles={css`
						html, body {
							overflow: hidden;
						}
				`}/>
			}
			<Cart open={siteState.cartOpen} />
			<main>
				<Transition>
					<div css={css`
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            ${mobile}{
							/* height: 100vh;
							min-height: -webkit-fill-available;
							min-height: fill-available;
							max-height: -webkit-fill-available;
							max-height: fill-available; */
            }
          `}>
						{children}
						<Footer 
							minimal={minimalFooter}
							fixed={fixedFooter} 
							color={footerColor}
							faded={footerFade}
							blackBg={blackFooterBg}
							css={css`margin-top: auto;`}
						/>
					</div>
				</Transition>
			</main>
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					cartOpen: false,
				}))}
				css={css`
          pointer-events: ${siteState.cartOpen? 'all' : 'none'};
      `}/>
		</>
	)
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
